import React, { useState } from 'react';
import axios from 'axios';
import { DatePicker, Button, Spin, Alert } from 'antd'; // Importamos los componentes de Ant Design
import 'antd/dist/reset.css'; // Importamos los estilos de Ant Design
import './OrderTotals.css';

const { RangePicker } = DatePicker; // Usamos RangePicker para seleccionar un rango de fechas

const OrderTotals = () => {
  const [totales, setTotales] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dates, setDates] = useState([null, null]);

  // Estados relevantes para filtrar
  const estadosPermitidos = [
    'enviado',
    'faltante',
    'facturado',
    'alistamiento',
    'pedidolisto',
    'plencontrados',
    'enviadoasistidobg',
    'busqueda',
    'epayco-completed',
    'completed',
    'processing',
  ];

  // Función para manejar la consulta al hacer submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const [startDate, endDate] = dates;

    if (!startDate || !endDate) {
      setError('Por favor, selecciona un rango de fechas.');
      setLoading(false);
      return;
    }

    try {
      // 1. Obtener todas las órdenes en el rango de fechas
      const ordersResponse = await axios.get('https://maemioficial.com/wp-json/wc/v3/orders', {
        auth: {
          username: process.env.REACT_APP_WC_KEY,
          password: process.env.REACT_APP_WC_SECRET,
        },
        params: {
          after: startDate.format('YYYY-MM-DD') + 'T00:00:00',
          before: endDate.format('YYYY-MM-DD') + 'T23:59:59',
          per_page: 100, // Ajusta esto según la cantidad de órdenes que esperas
        },
      });

      const orders = ordersResponse.data;

      // 2. Obtener los totales de pedidos por estado (sin filtro de fecha)
      const totalsResponse = await axios.get('https://maemioficial.com/wp-json/wc/v3/reports/orders/totals', {
        auth: {
          username: process.env.REACT_APP_WC_KEY,
          password: process.env.REACT_APP_WC_SECRET,
        },
      });

      const totalsByState = totalsResponse.data;

      // 3. Filtrar los estados permitidos y las órdenes dentro de esos estados
      const filteredTotals = totalsByState
        .filter((estado) => estadosPermitidos.includes(estado.slug)) // Filtrar solo los estados permitidos
        .map((estado) => {
          const totalOrdersForState = orders.filter((order) => order.status === estado.slug).length;
          return {
            ...estado,
            total: totalOrdersForState,
          };
        });

      // 4. Sumar el total de órdenes de los estados permitidos
      const totalOrders = filteredTotals.reduce((acc, estado) => acc + estado.total, 0);

      setTotales({
        totalOrders,
        breakdown: filteredTotals,
      });
      
      // Verificar si hay totales, si no, mostrar error
      if (totalOrders === 0) {
        setError('No se encontraron datos para las fechas seleccionadas.');
      }
    } catch (error) {
      setError('Error al obtener los datos.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Totales de Pedidos</h1>
      <p className='subtitle'>
        Aquí puedes consultar los totales de órdenes por fechas específicas.
      </p>
  
      {/* Formulario para seleccionar las fechas */}
      <form onSubmit={handleSubmit}>
        <label>
          Selecciona un rango de fechas:<br />
          <RangePicker
            onChange={(dates) => setDates(dates)}
            format="YYYY-MM-DD"
            required
          />
        </label>
        <Button type="primary" htmlType="submit">
          Consultar Totales
        </Button>
      </form>
  
      {loading ? (
        <Spin tip="Cargando..." />
      ) : error ? (
        <Alert message={error} type="error" />
      ) : totales ? (
        <div>
          <h3 className='titleResult'>Resultados:</h3>
          <p>Total de Órdenes: {totales.totalOrders}</p>
  
          {/* Desglose de órdenes por estado (solo los estados con datos) */}
          <h3>Desglose por estado:</h3>
          <ul className='listResult'>
            {totales.breakdown
              .filter((estado) => estado.total > 0) // Solo mostrar los estados con órdenes
              .map((estado) => (
                <li key={estado.slug}>
                  {estado.name}: {estado.total}
                </li>
              ))}
          </ul>
        </div>
      ) : null} {/* No mostramos nada si no hay totales */}
    </div>
  );
};

export default OrderTotals;
