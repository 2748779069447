import React from 'react';
import './App.css';
import OrderTotals from './ordersTotals.jsx';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <OrderTotals /> {/* Asegúrate de que este componente esté correctamente llamado */}
      </header>
    </div>
  );
}

export default App;
